.brand {
  display: flex;
  align-items: center;
  width: max-content;
  margin-top: 2vh;
}

.brand img {
  width: calc(2vw + 30px);
  margin: 3vh 3vw;
}

.brand h3 {
  font-size: calc(1vw + 30px);
}