*{
	margin: 0;
	padding: 0;
	text-decoration: none;
	border: none;
	outline: none;
  color: black;
}

body {
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100vw;
  height: 100vh;
}

.main-container {
  width: calc(98vw - 50px);
  height: calc(97vh - 50px);
  position: absolute;
  top: calc(1.5vh + 25px);
  left: calc(1vw + 25px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* border-style: solid; */
}

.v-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.h-container {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: space-around;
  flex-direction: row;
}

a {
  cursor: pointer;
  display: block;
  width: max-content;
}

/* ======== Colors ============== */

/* General ------------ */

.none-color {
  background-color: rgb(188, 188, 188);
}

.heavy-none-color {
  background-color: rgb(133, 133, 133);
  /* background-color: #3090B9; */
}

.ligth-none-color {
  background-color: rgb(241, 241, 241);
}

.shadow-none-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11), 5px 5px 40px rgba(191, 191, 191, 0.25);
}

.selected-none-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(162, 162, 162, 0.15), 5px 5px 20px rgba(190, 190, 190, 0.35);
  transform: scale(1.05);
}

/* Ciencias ------------ */

/* .Ciencias-color {
  background-color: rgba(204, 220, 233, 1);
}

.ligth-Ciencias-color {
  background-color: rgb(246, 251, 255);
}

.shadow-Ciencias-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11), 5px 5px 40px rgba(92, 148, 201, 0.25);
}

.selected-Ciencias-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(46, 109, 162, 0.15), 5px 5px 20px rgba(59, 134, 191, 0.35);
  transform: scale(1.05);
} */

.Ciencias-color {
  background-color: rgb(252, 196, 171);
}

.heavy-Ciencias-color {
  background-color: rgb(237, 106, 45);
  /* background-color: #3090B9; */
}

.ligth-Ciencias-color {
  background-color: rgb(255, 250, 246);
}

.shadow-Ciencias-color {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 5px 5px 30px rgba(248, 166, 90, 0.35);
}

.selected-Ciencias-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(255, 126, 52, 0.15), 5px 5px 25px rgba(255, 134, 47, 0.35);
  transform: scale(1.05);
}

.disabled-Ciencias-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: default;;
}

.disabled-Ciencias-color:hover {
  transform: scale(1.00);
}

/* Cimas ------------ */

.Cimas-color {
  background-color: rgb(249, 208, 208);
}

.heavy-Cimas-color {
  background-color: rgb(203, 71, 71);
}

.ligth-Cimas-color {
  background-color: rgb(255, 246, 247);
}

.shadow-Cimas-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 4px 4px 20px rgba(201, 92, 92, 0.25);
}

.selected-Cimas-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(208, 87, 87, 0.15), 5px 5px 20px rgba(208, 87, 87, 0.35);
  transform: scale(1.05);
}

.disabled-Cimas-color {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  cursor: default;;
}

.disabled-Cimas-color:hover {
  transform: scale(1.00);
}