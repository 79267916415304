button {
  cursor: pointer;
}

.action-button {
  /* width: calc(3vw + 200px);
  height: 8.5vh; */
  padding: 2vh 4vw;
  display: flex;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11), 5px 5px 20px #5c94c91b;
  transition: width 0.7s, height 0.7s, transform 0.25s;
}

.action-button h3 {
  font-size: calc(1vw + 10px);
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 24px;
}

.primary h3 {
  color: white;
}

.secondary {
  background-color: white;
}

.secondary h3 {
  color: black;
}

.small h3 {
  font-size: calc(0.7vw + 5px);
  font-weight: 300;
}

.small {
  border-radius: 10px;
  padding: 0.3vh 1.2vw;
}