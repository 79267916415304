.profile-notshown {
  position: absolute;
  right: 0;
  top: 0;
}

.profile-notshown .data {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5vh calc(15px + 2vw);
  font-size: 20px;
}

.profile-notshown .data h4 {
  margin-right: 10px;
}

.profile-shown {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all .5s ease;
  border-radius: 10px;
}

.profile-shown .data {
  width: calc(10vw + 300px);
  height: 100%;
  background-color: white;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  animation-duration: 1s;
  animation-name: slidein;
}

.profile-shown .back {
  width: calc(88vw - 350px);
  height: 100%;
}

.profile-shown .data a img {
  width: 40px;
  position: relative;
  top: 10px;
  left: 10px;
}
.profile-shown .data div img {
  height: 20vh;
  position: relative;
  top: 10vh;
  left: calc(5vw + 150px - 10vh);
}
.profile-shown .data h4 {
  width: max-content;
  position: relative;
  top: 14vh;
  margin: auto;
  font-size: 4vh;
}
.profile-shown .data p {
  width: max-content;
  position: relative;
  top: 15vh;
  margin: auto;
}
.profile-shown .data h5 {
  width: max-content;
  position: relative;
  top: 34vh;
  margin: auto;
  font-size: 3vh;
}

.signout {
  position: relative;
  left: calc(8vw + 30px);
  top: 30vh;
  border-radius: 5px;
  /* padding: 1vh 2vh; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.11), 5px 5px 20px #5c94c91b; */
}

@keyframes slidein {
  from {
    width: 0;
  }
  to {
    width: calc(10vw + 300px);
  }
}