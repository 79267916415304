.createStudent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0;
  /* padding-top: 0.5vh; */
}

.inputs {
  display: flex;
  justify-content: space-between;
  padding: 0 3vw;
}

.buttons {
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.row {
  display: flex;
  height: max-content;
  width: 100%;
  justify-content: space-evenly;
}

.col {
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: space-between;
  padding: 0.5vh 2vw;
}

.createStudent .payments {
  width: 50%;
}