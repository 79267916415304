
.card-student {
    display: flex;
    margin-top: 5vh;
    margin-right: 5vw;
}

.card-student .left {
    width: 15vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2vw;
}

.card-student .left img {
    width: 12vw;
}

.card-student .right {
    /* width: 100%; */
    height: 100%;
    margin: 0 5vh;
    display: flex;
    font-size: 2.2vw;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.card-student .sello {
    position: absolute;
    z-index: 1;
    transform: rotate(-25deg);
    top: 63vh;
    left: 60vw;
    width: 25vw;
}

.card-student .sello img {
    width: 100%;
}