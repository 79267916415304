.header-main {
  width: 100vw;
  height: 13vh;
  border-bottom: 1px solid rgb(0, 0, 0);  
}

.content-main {
  height: 79.1vh;
  background-image: url("https://img.freepik.com/fotos-premium/fondo-molecula-cientifica-ondas-flujo-medicina-ciencia-tecnologia-quimica-fondo-pantalla_230610-906.jpg");
  background-size: cover  ;
  display: flex;
  justify-content: space-between;
}

.content-main div {
  width: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-main {
  display: flex;
  justify-content: space-around;
  background-color: black;
  padding: 3vh 0;
}

.footer-main h5 {
  color: azure;
}