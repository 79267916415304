.background {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -3;
  /* transition: all .5s ease; */
}

.background-container {
  width: calc(98vw - 50px);
  height: calc(97vh - 50px);
  position: absolute;
  top: calc(1.5vh + 25px);
  left: calc(1vw + 25px);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: -2;
  border-radius: 10px;
  transition: all .5s ease;
}

.dyone-stamp {
  position: absolute;
  right: calc(1vw + 25px);
  bottom: 0.7vh;
  display: flex;
}

.dyone-stamp h3 {
  font-size: 3vh;
}

.dyone-stamp h5 {
  padding-top: 1.2vh;
  padding-right: 0.2vw;
}

@media only screen and (max-width: 400px) {
  .background {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    border-radius: 0;
  }
}