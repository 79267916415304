.input {
  position: relative;
  padding-top: 1vh;
  width: 100%;
  margin: 0 15px;
}

.input input {
  width: calc(100% - 5px);
  padding: 1vh 0px 5px 5px;
  font-size: calc(1vh + 10px);
  color: rgb(0, 0, 0);
  /* margin-bottom: 10px; */
  border: none;
  border-bottom: 1px solid rgb(0, 0, 0);
  outline: none;
  background: transparent;
}

.input label {
  position: absolute;
  /* top: 10px; */
  left: 5px;
  padding: 10px 0;
  /* font-size: 18px; */
  color: rgb(0, 0, 0);
  pointer-events: none;
  transition: 0.5s;
}

.input input:focus ~ label,
.input input:valid ~ label{
  top: -5px;
  left: 0;
  padding: 0;
  color: #0c1213;
  font-size: 12px;
}

.input input:-webkit-autofill,
.input input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

/* .input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
} */

.select {
  margin: -5px 15px;
  align-items: center;
  border: none;
  padding-bottom: -20px;
}

.select label {
  color: #0c1213;
  font-size: 12px;
  padding: 5px 0;
  padding-top: 10px;
  padding-left: 5px;
}

.select select {
  border-bottom: 1px solid rgb(0, 0, 0);
  padding-bottom: 10px;
  padding-top: 5px;
}